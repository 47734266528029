import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
  key: 'walkthroughTooltip',
  storage: process.browser ? localStorage : undefined,
});

interface scrollProps {
  dashboard_change_preferences: boolean;
  dashboard_generate_cv: boolean;
}

interface walkthroughTooltip {
  dashboard_explore: boolean;
  dashboard_change_preferences: boolean;
  dashboard_generate_cv: boolean;
  dashboard_cover_letter: boolean;
  dashboard_ai_question_reply: boolean;
  dashboard_autofill_extension: boolean;
  explore_search_type: boolean;
  ai_search_search_input: boolean;
  ai_search_message_input: boolean;
  ai_search_results: boolean;
}

// default for every walkthrough should be true
export const walkthroughTooltipState = atom<walkthroughTooltip>({
  key: 'walkthrough_tooltip',
  default: {
    dashboard_explore: true,
    dashboard_change_preferences: true,
    dashboard_generate_cv: true,
    dashboard_cover_letter: true,
    dashboard_ai_question_reply: true,
    dashboard_autofill_extension: true,
    explore_search_type: true,
    ai_search_search_input: true,
    ai_search_message_input: true,
    ai_search_results: true,
  },
  effects_UNSTABLE: [persistAtom],
});

// default for every scroll should be false
export const walkthroughScrollState = atom<scrollProps>({
  key: 'scroll_walkthrough',
  default: {
    dashboard_change_preferences: false,
    dashboard_generate_cv: false,
  },
  effects_UNSTABLE: [persistAtom],
});
