import React, { memo, useState } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';

import { IconButton } from 'components';
import Form from 'components/candidate/Form';
import { Button, ButtonV2 } from 'components/form';
import { useNotification } from 'hooks/useNotification';
import { useCandidate } from 'hooks/useCandidate';
import { useResponsive } from 'hooks/useResponsive';
import { PAGE_ROUTES } from 'lib/page-routes';
import { useApp } from 'lib/contexts/save-in-app-context';
import { useAuth } from 'lib/providers/AuthProvider';
import { parseErrorResponse } from 'lib/utils/parser';
import { isAndroid } from 'utils/dom';
import { APP_LINK } from 'utils/urls';
import { signInModalInstance } from '../SignInModal';
import VerifyOTP from '../VerifyOTP';

import styles from './SaveInApp.module.scss';

export interface SaveInAppProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  onComplete: (completed: boolean | undefined) => void;
  onCancel: () => void;
}

export const SaveInApp: React.FC<SaveInAppProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    onComplete,
    onCancel,
  }: SaveInAppProps) => {
    const contentClassNames = cn([styles.SaveInApp], className);
    const notificationInstance = useNotification();
    const screens = useResponsive();
    const [askOTPVerification, setOTPVerification] = useState(false);
    const [email, setEmail] = useState<string>('');
    const { isTemporaryCandidate, isCandidate, updateUserApiResponse } =
      useAuth();
    const router = useRouter();
    const { getCallback } = useApp();
    const callbackFn = getCallback();

    const { loginCandidate, updateCandidateEmail } = useCandidate();

    const onSaveInAppContinue = async (data: { email: string }) => {
      const { email } = data;
      setEmail(email);
      try {
        const userApiResponse = await updateCandidateEmail({
          email,
        });
        updateUserApiResponse(userApiResponse);

        if (userApiResponse) {
          if (callbackFn) {
            callbackFn();
          } else {
            onComplete(true);
          }
        }
      } catch (error) {
        if (isTemporaryCandidate) {
          const message = parseErrorResponse(error);
          if (message === 'Email has already been taken') {
            const response = await loginCandidate({ email });
            if (response) setOTPVerification(true);
          }
          onComplete(true);
        } else {
          notificationInstance.handleExceptionError(error);
        }
      }
    };

    const onAppDownloadClick = () => {
      router.push(APP_LINK);
    };

    const onUseHuzzleClick = async () => {
      try {
        if (isCandidate) {
          router.push(PAGE_ROUTES.CANDIDATE_DASHBOARD);
          onCancel();
        } else {
          await signInModalInstance();
          router.push(PAGE_ROUTES.CANDIDATE_DASHBOARD);
          onCancel();
        }
      } catch (error) {
        console.error(error);
      }
    };

    return (
      <section
        className={contentClassNames}
        style={style}
        data-testid="SaveInApp"
      >
        {isCandidate && callbackFn ? (
          <div className={styles.saved}>
            <p className={styles.emoji}>💪</p>
            <p className={styles.title}>Saved</p>
            <p className={styles.desc}>
              This opportunity is waiting for you in the App along with much
              more!
            </p>
            {screens.sm ? (
              <IconButton
                iconName={
                  isAndroid() ? 'play-store-download' : 'app-store-download'
                }
                size="auto"
                className={styles.download}
                onClick={onAppDownloadClick}
              />
            ) : (
              <ButtonV2
                width={300}
                onClick={onUseHuzzleClick}
                className={styles.download}
              >
                Use huzzle
              </ButtonV2>
            )}
            <Button
              variant="text"
              color="black"
              size="small"
              onClick={onCancel}
              className={styles.continue}
            >
              Continue browsing
            </Button>
          </div>
        ) : askOTPVerification ? (
          <VerifyOTP
            email={email}
            onComplete={onComplete}
            isLoginPopup={false}
          />
        ) : (
          <Form onContinue={onSaveInAppContinue} />
        )}
      </section>
    );
  }
);

SaveInApp.displayName = 'SaveInApp';
