import { CompanyResponse } from './company';
import { TouchpointType } from './touchpoint';
import { StudentSocietyResponse } from './student-society';

export interface CandidateApplicationType {
  id: string;
  title: string;
  kind?: string;
  touchpointable_type?: TouchpointType;
  creatable_for?: CompanyResponse | StudentSocietyResponse;
  apply_url?: string;
  slug: string;
  posted_at?: string;
  is_eligible_for_auto_apply?: boolean;
}

export interface CandidateApplicationsResponse {
  matched: ResponseFormat;
  preferred: ResponseFormat;
  saved: ResponseFormat;
  saved_and_matched: ResponseFormat;
}

interface ResponseFormat {
  data: TouchpointResponse[];
}

interface TouchpointResponse {
  attributes: CandidateApplicationType;
  id: string;
  type: 'touchpoint';
}

export enum ApplicationResponseModalEnum {
  questionResponse = 'questionResponse',
  coverLetter = 'coverLetter',
}

export interface CandidateApplicationAIResponse {
  suggestions: string;
}
