import React from 'react';
import { create, InstanceProps } from 'react-modal-promise';

import { IconButton } from 'components';
import Modal from 'components/Modal';

import { useResponsive } from 'hooks/useResponsive';
import CVUpload from '../CVUpload';
import styles from './CVUploadModal.module.scss';

const ModalInstance: React.FC<InstanceProps<Headers>> = ({
  isOpen,
  onResolve,
  onReject,
}) => {
  const screens = useResponsive();
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onReject}
      contentClassName={styles.container}
    >
      {screens.sm ? null : (
        <IconButton
          size="small"
          iconName="close"
          className={styles.cancelIcon}
          onClick={() => onReject()}
        />
      )}
      <CVUpload onDone={onResolve} className={styles.content} />
    </Modal>
  );
};

export const CVUploadModalInstance = create(ModalInstance);
