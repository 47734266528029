import React, { useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import cn from 'classnames';
import Icon, { IconName, IconSize } from 'components/Icon';
import styles from './ActionMenu.module.scss';

export type ActionOption = {
  id: string;
  label: string | null | React.ReactNode;
  icon?: IconName;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onHover?: (event: React.MouseEvent<HTMLLIElement>) => void;
  danger?: boolean;
  disabled?: boolean;
  isIconPrimaryColor?: boolean;
  secondaryColor?: boolean;
  options?: Array<ActionOption>;
  actionElement?: React.ReactNode;
};

type ActionMenuProps = {
  options: Array<ActionOption>;
  align?: 'left' | 'right' | 'auto';
  /** Custom class name */
  className?: string;
  actionElement?: React.ReactNode;
  listClassName?: string;
  triggerButtonClassName?: string;
  menuButtonClassName?: string;
  triggerIconClassName?: string;
  triggerIconSize?: IconSize;
  subOptionsClassName?: string;
  optionClassName?: string;
  subOptionsAlignClassName?: { right: string; left: string; auto?: string };
  disabled?: boolean;
  isModal?: boolean;
  modalHeight?: number;
  showOptionFullContent?: boolean;
  optionHeight?: number | null;
  borderBetweenOption?: boolean;
  actionIcon?: IconName;
};

export const ActionMenu: React.FC<ActionMenuProps> = ({
  options,
  align = 'left',
  className = '', // custom class name
  actionElement,
  listClassName,
  triggerButtonClassName,
  menuButtonClassName,
  triggerIconClassName,
  triggerIconSize = 'auto',
  subOptionsClassName,
  optionClassName,
  subOptionsAlignClassName,
  disabled,
  isModal,
  showOptionFullContent,
  modalHeight = 0,
  optionHeight,
  borderBetweenOption = false,
  actionIcon,
}) => {
  const [openDirectionBottom, setOpenDirectionBottom] = useState<boolean>(true);

  const handleButtonClick: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    const dropdownOptionsHeight = optionHeight
      ? options.length * optionHeight
      : options.length * 110;

    const pageSize =
      isModal && modalHeight ? modalHeight : document.body.scrollHeight;

    if (pageSize - event.pageY < dropdownOptionsHeight) {
      setOpenDirectionBottom(false);
    } else {
      setOpenDirectionBottom(true);
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const getTriggerIconClass = (providedClass: string | undefined) => {
    return providedClass || styles.buttonIcon;
  };

  return (
    <Menu as="div" className={cn(styles.container, className)}>
      <button
        className={cn(styles.buttonContainer, triggerButtonClassName)}
        onClick={!disabled ? handleButtonClick : () => null}
        disabled={disabled}
      >
        <Menu.Button
          className={cn(
            { [styles.button]: !actionElement },
            menuButtonClassName
          )}
          disabled={disabled}
        >
          {actionElement ? (
            actionElement
          ) : (
            <Icon
              iconName={actionIcon || 'icon_ellipsis'}
              size={triggerIconSize}
              className={getTriggerIconClass(triggerIconClassName)}
            />
          )}
        </Menu.Button>
      </button>
      <Transition
        enter={styles.enter}
        enterFrom={styles['enter-from']}
        enterTo={styles['enter-to']}
        leave={styles.leave}
        leaveFrom={styles['leave-from']}
        leaveTo={styles['leave-to']}
        as="div"
      >
        {options?.length ? (
          <Menu.Items
            as="ul"
            static
            className={cn(
              styles.options,
              openDirectionBottom ? styles.optionsTop : styles.optionsBottom,
              alignClassName[align],
              listClassName
            )}
          >
            {options.map((option) => (
              <div
                key={option.id}
                className={cn(optionClassName, {
                  [styles['add-border']]: borderBetweenOption,
                  [styles.option]: option.label !== null,
                })}
              >
                {option.options ? (
                  <Menu as="div" className={styles.subContainer}>
                    <div onClick={(e) => e?.stopPropagation()}>
                      <Menu.Button
                        as="li"
                        key={option.id}
                        className={styles.optionsItem}
                        onMouseEnter={option.onHover}
                      >
                        {option.label}
                        {option.icon && <Icon iconName={option.icon} />}
                      </Menu.Button>
                    </div>
                    <Menu.Items
                      as="ul"
                      className={cn(
                        styles.subOptions,
                        subOptionsAlignClassName
                          ? subOptionsAlignClassName[align]
                          : alignClassName[align],
                        subOptionsClassName
                      )}
                    >
                      {option.options.map((option) => (
                        <Menu.Item as="li" key={option.id}>
                          <button
                            onClick={option.onClick}
                            className={cn(styles.item, {
                              [styles.danger]: option.danger,
                              [styles.primaryColor]: option.isIconPrimaryColor,
                              [styles.secondaryColor]: option.secondaryColor,
                            })}
                          >
                            <p
                              className={cn(styles.label, {
                                [styles.labelNoWrap]: showOptionFullContent,
                              })}
                            >
                              {option.label}
                            </p>
                            <div>{option.actionElement}</div>
                            {option.icon && <Icon iconName={option.icon} />}
                          </button>
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Menu>
                ) : option.label ? (
                  <Menu.Item as="li">
                    {({ close }) => (
                      <button
                        className={styles['item-button']}
                        onClick={option.onClick}
                      >
                        <div
                          className={cn(styles.item, {
                            [styles.danger]: option.danger,
                            [styles.disabled]: option.disabled,
                            [styles.primaryColor]: option.isIconPrimaryColor,
                            [styles.secondaryColor]: option.secondaryColor,
                          })}
                          onClick={close}
                        >
                          <p
                            className={cn(styles.label, {
                              [styles.labelNoWrap]: showOptionFullContent,
                            })}
                          >
                            {option.label}
                          </p>
                          <div className={styles.actionElement}>
                            {option.actionElement}
                          </div>
                          {option.icon && <Icon iconName={option.icon} />}
                        </div>
                      </button>
                    )}
                  </Menu.Item>
                ) : null}
              </div>
            ))}
          </Menu.Items>
        ) : null}
      </Transition>
    </Menu>
  );
};

const alignClassName = {
  left: styles.left,
  right: styles.right,
  auto: styles.auto,
};
