import React, {
  memo,
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import capitalize from 'lodash/capitalize';
import { useRecoilState } from 'recoil';

import Icon from 'components/Icon';
import { ButtonV3 } from 'components/ComponentV2';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import {
  walkthroughTooltipState,
  walkthroughScrollState,
} from 'lib/atoms/walkthroughTooltipAtom';

import styles from './WalkthroughTooltip.module.scss';

export enum WalkthroughTooltipType {
  DASHBOARD_EXPLORE = 'dashboard_explore',
  DASHBOARD_CHANGE_PREFERENCES = 'dashboard_change_preferences',
  DASHBOARD_GENERATE_CV = 'dashboard_generate_cv',
  DASHBOARD_COVER_LETTER = 'dashboard_cover_letter',
  DASHBOARD_AI_QUESTION_REPLY = 'dashboard_ai_question_reply',
  DASHBOARD_AUTOFILL_EXTENSION = 'dashboard_autofill_extension',
  EXPLORE_SEARCH_TYPE = 'explore_search_type',
  AI_SEARCH_SEARCH_INPUT = 'ai_search_search_input',
  AI_SEARCH_MESSAGE_INPUT = 'ai_search_message_input',
  AI_SEARCH_RESULTS = 'ai_search_results',
}

export enum AiSearchWalkthroughProgressCount {
  AI_SEARCH_MESSAGE_INPUT = 1,
  AI_SEARCH_RESULTS = 2,
  AI_SEARCH_TOTAL_STEPS = AI_SEARCH_RESULTS,
}

export enum DashboardWalkthroughProgressCount {
  DASHBOARD_CHANGE_PREFERENCES = 1,
  DASHBOARD_GENERATE_CV = 2,
  DASHBOARD_COVER_LETTER = 3,
  DASHBOARD_AI_QUESTION_REPLY = 4,
  DASHBOARD_AUTOFILL_EXTENSION = 5,
  DASHBOARD_TOTAL_STEPS = DASHBOARD_AUTOFILL_EXTENSION,
}

export enum WalkthroughScrollType {
  DASHBOARD_CHANGE_PREFERENCES = 'dashboard_change_preferences',
  DASHBOARD_GENERATE_CV = 'dashboard_generate_cv',
}

export interface WalkthroughTooltipProps {
  /** Custom class name */
  className?: string;
  childrenClassName?: string;
  containerClassName?: string;
  walkthroughType: WalkthroughTooltipType;
  previousProgressWalkthrough?: WalkthroughTooltipType;
  scrollToNextWalkthrough?: WalkthroughScrollType;
  scrollWalkthroughRef?: RefObject<HTMLElement>;
  /** color */
  color?: 'blue' | 'dark';
  /** position of tooltip */
  position?: 'top' | 'bottom' | 'left' | 'right';
  /** alignment according to position */
  alignment?: 'start' | 'center' | 'end';
  /** children */
  children: React.ReactNode;
  disable?: boolean;
  setIsWalkthroughOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  /** tooltip content */
  title?: string | ReactElement;
  description?: string;
  progressCount?: number;
  progressPosition?: number;
  buttonOneText?: string;
  haveBorder?: boolean;
  haveBoxShadow?: boolean;
  closeWalkthrough?: boolean;
}

export const WalkthroughTooltip: React.FC<WalkthroughTooltipProps> = memo(
  ({
    className = '',
    childrenClassName,
    containerClassName,
    walkthroughType,
    previousProgressWalkthrough,
    scrollToNextWalkthrough,
    scrollWalkthroughRef,
    color = 'blue',
    position = 'bottom',
    alignment = 'center',
    children,
    disable = false,
    setIsWalkthroughOpen,
    title,
    description,
    progressCount,
    progressPosition,
    buttonOneText,
    haveBorder,
    haveBoxShadow,
    closeWalkthrough: closeWalkthroughTooltip,
  }: WalkthroughTooltipProps) => {
    const [isOpen, setOpen] = useState(false);
    const walkthroughRef = useRef<HTMLDivElement>(null);
    const [walkthroughTooltip, setWalkthroughTooltip] = useRecoilState(
      walkthroughTooltipState
    );
    const [walkthroughScroll, setWalkthroughScroll] = useRecoilState(
      walkthroughScrollState
    );

    /* to add walkthroughType in localstorage 
    first time user open page */
    useEffect(() => {
      if (walkthroughTooltip[walkthroughType] === undefined) {
        setWalkthroughTooltip({
          ...walkthroughTooltip,
          [walkthroughType]: true,
        });
      }
      if (
        scrollToNextWalkthrough &&
        walkthroughScroll[scrollToNextWalkthrough] === undefined
      ) {
        setWalkthroughScroll({
          ...walkthroughScroll,
          [scrollToNextWalkthrough]: false,
        });
      }
    }, []);

    useEffect(() => {
      if (
        disable ||
        (previousProgressWalkthrough &&
          walkthroughTooltip[previousProgressWalkthrough] === true)
      ) {
        setOpen(false);
      } else setOpen(walkthroughTooltip[walkthroughType]);
    }, [previousProgressWalkthrough, disable, walkthroughTooltip]);

    // to scroll walkthrough into viewport
    useEffect(() => {
      if (
        scrollWalkthroughRef &&
        walkthroughScroll[
          walkthroughType as unknown as WalkthroughScrollType
        ] === true
      ) {
        scrollWalkthroughRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });

        setWalkthroughScroll({
          ...walkthroughScroll,
          [walkthroughType]: false,
        });
      }
    }, [scrollWalkthroughRef, walkthroughScroll]);

    // to konw walkthrough state in parent
    useEffect(() => {
      if (setIsWalkthroughOpen) setIsWalkthroughOpen(isOpen);
    }, [isOpen]);

    useOnClickOutside({
      ref: walkthroughRef,
      onOutsideClick: () => setOpen(false),
    });

    const contentPositionClassNames = cn(
      styles['walkthrough-position'],
      className,
      {
        [`${styles[`color${capitalize(color)}`]}`]: color,
        [`${styles[`position${capitalize(position)}`]}`]: position,
        [`${styles[`horizontal${capitalize(alignment)}`]}`]:
          position === 'top' || position === 'bottom',
        [`${styles[`vertical${capitalize(alignment)}`]}`]:
          position === 'right' || position === 'left',
      }
    );

    const arrowIcon =
      position === 'top' || position === 'bottom'
        ? 'icon_tippy_x'
        : 'icon_tippy_y';

    const closeWalkthrough = useCallback(() => {
      if (isOpen && walkthroughTooltip[walkthroughType]) {
        setWalkthroughTooltip({
          ...walkthroughTooltip,
          [walkthroughType]: false,
        });
        setOpen(false);
      }
    }, [isOpen, walkthroughTooltip, walkthroughTooltip[walkthroughType]]);

    useEffect(() => {
      if (closeWalkthroughTooltip) {
        closeWalkthrough();
      }
    }, [closeWalkthroughTooltip]);

    const onNextClick = useCallback(() => {
      closeWalkthrough();
      if (scrollToNextWalkthrough) {
        setWalkthroughScroll({
          ...walkthroughScroll,
          [scrollToNextWalkthrough]: true,
        });
      }
    }, [closeWalkthrough, walkthroughScroll, scrollToNextWalkthrough]);

    const progress = useMemo(() => {
      if (!progressCount || !progressPosition) return;

      const dots = [];
      for (let index = 1; index <= progressCount; index++) {
        dots.push({
          id: index,
          isCurrent: index === progressPosition ? true : false,
        });
      }
      return dots;
    }, [progressCount, progressPosition]);

    return (
      <section
        className={cn(styles.walkthrough, containerClassName)}
        ref={walkthroughRef}
      >
        <div
          className={cn(childrenClassName, {
            [styles['highlight-border']]: isOpen && haveBorder,
            [styles['box-shadow']]: isOpen && haveBoxShadow,
          })}
          onClick={closeWalkthrough}
        >
          {children}
        </div>
        {isOpen && (
          <div className={contentPositionClassNames} data-testid="Walkthrough">
            <div className={styles['arrow-container']}>
              <Icon className={styles.arrow} iconName={arrowIcon} size="auto" />
            </div>
            <div className={styles['walkthrough-container']}>
              {title ? (
                <div className={styles['walkthrough-title']}>{title}</div>
              ) : null}
              {description ? (
                <div className={styles['walkthrough-desc']}>{description}</div>
              ) : null}
              {buttonOneText || progressCount ? (
                <div
                  className={cn(styles['action-container'], {
                    [styles['has-progress']]: progressCount,
                  })}
                >
                  {progressCount ? (
                    <div className={styles['progress-container']}>
                      {progress?.map(({ id, isCurrent }) => (
                        <Icon
                          key={id}
                          iconName={'icon_dot'}
                          className={cn(styles.dot, {
                            [styles.isCurrent]: isCurrent,
                          })}
                        />
                      ))}
                    </div>
                  ) : null}
                  {buttonOneText ? (
                    <ButtonV3
                      color="secondary"
                      borderless={true}
                      className={styles['walkthrough-button']}
                      onClick={progressCount ? onNextClick : closeWalkthrough}
                    >
                      {buttonOneText}
                    </ButtonV3>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        )}
      </section>
    );
  }
);

WalkthroughTooltip.displayName = 'WalkthroughTooltip';
