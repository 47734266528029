import cn from 'classnames';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { saveAs } from 'file-saver';
import React, { memo, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { Icon, Spinner } from 'components';
import ActionMenu, { ActionOption } from 'components/ActionMenu';
import { Button, ButtonV2 } from 'components/form';
import { Col, Row } from 'components/layout';
import { useResponsive } from 'hooks/useResponsive';
import { useCandidate } from 'hooks/useCandidate';
import { useNotification } from 'hooks/useNotification';
import { useAuth } from 'lib/providers/AuthProvider';
import { convertToBase64 } from 'utils/format';

import styles from './CVUpload.module.scss';

export interface CVUploadProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  onDone: () => void;
  showWithoutModal?: boolean;
  onResumeSelectDone?: ({ name, file }: { name: string; file: string }) => void;
}

export const CVUpload: React.FC<CVUploadProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    onDone,
    showWithoutModal = false,
    onResumeSelectDone,
  }: CVUploadProps) => {
    const contentClassNames = cn(
      {
        [styles.CVUpload]: true,
      },
      className
    );

    const screens = useResponsive();
    const notificationInstance = useNotification();
    const { updateCandidateResume, removeCandidateResume } = useCandidate();
    const [isReplaceCVClicked, setIsReplaceCVClicked] = useState(false);
    const [isCVUploading, setIsCVUploading] = useState(false);

    const { candidate } = useAuth();

    const { resume, resume_url } = candidate || {};
    const { content_type, filename, updated_at } = resume || {};

    const diff = updated_at
      ? differenceInSeconds(new Date(), new Date(updated_at))
      : 0;
    const updatedAt = updated_at
      ? formatDistanceToNow(new Date(updated_at), {
          addSuffix: true,
        })
      : null;

    const getFileType = (type: string) => {
      switch (type) {
        case 'application/pdf':
          return 'PDF';
        case 'application/rtf':
          return 'RTF';
        case 'text/plain':
          return 'TXT';
        case 'application/msword':
          return 'DOC';
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          return 'DOCX';
      }
    };

    const onRemove = async () => {
      try {
        await removeCandidateResume();
      } catch (error) {
        notificationInstance.handleExceptionError(error);
      }
    };

    const onReplace = () => setIsReplaceCVClicked(true);
    const onBackClick = () => setIsReplaceCVClicked(false);

    const onBackClickMobile = () => {
      if (isReplaceCVClicked) setIsReplaceCVClicked(false);
      else onDone();
    };

    const onDownload = () => {
      if (resume_url) saveAs(resume_url);
    };

    const menuOptions = [
      {
        id: '1',
        label: 'Download',
        icon: 'download',
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          onDownload();
        },
      },
      {
        id: '2',
        label: 'Replace',
        icon: 'replace',
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          onReplace();
        },
      },
      {
        id: '3',
        label: 'Remove',
        icon: 'trash',
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          onRemove();
        },
        danger: true,
      },
    ];

    const onDrop = async (acceptedFiles: Array<File>) => {
      const file = acceptedFiles[0];
      if (!file) return;

      if (file.size > 2097152) {
        notificationInstance.error({
          title: 'File size exceeded!',
          message: 'Please upload a file of size upto 2MB',
        });
        return;
      }

      setIsCVUploading(true);
      try {
        const fileInBase64String = await convertToBase64(file);
        if (showWithoutModal && onResumeSelectDone) {
          onResumeSelectDone({ name: file.name, file: fileInBase64String });
        } else {
          const response = await updateCandidateResume({
            name: file.name,
            file: fileInBase64String,
          });
          if (response) {
            setIsReplaceCVClicked(false);
            setIsCVUploading(false);
          }
        }
      } catch (error) {
        notificationInstance.handleExceptionError(error);
      }
    };

    const { getRootProps, getInputProps, open } = useDropzone({
      onDrop,
      noClick: true,
      accept:
        'application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/rtf,text/plain',
      maxFiles: 1,
    });

    if (!candidate && !showWithoutModal) return null;

    return (
      <div className={contentClassNames} style={style} data-testid="CVUpload">
        <section className={cn({ [styles.content]: !showWithoutModal })}>
          {showWithoutModal ? null : (
            <>
              {isReplaceCVClicked && !screens.sm ? (
                <div onClick={onBackClick} className={styles.back}>
                  <Icon iconName="chevron-left" size="large" />
                  <p>Back</p>
                </div>
              ) : null}
              {screens.sm ? (
                <div onClick={onBackClickMobile} className={styles.back}>
                  <Icon iconName="chevron-left" size="xxlarge" />
                </div>
              ) : null}
              <div className={styles.title}>
                {isReplaceCVClicked ? 'Replace CV' : 'CV'}
              </div>
            </>
          )}
          {filename && !isReplaceCVClicked && !showWithoutModal ? (
            <div className={styles.file}>
              <Row align="start">
                {content_type ? (
                  <Col className={styles.fileType}>
                    {getFileType(content_type)}
                  </Col>
                ) : null}
                <Col span={17} align="center" className={styles.fileInfo}>
                  <p className={styles.fileName}>{filename}</p>
                  {diff >= 0 && <p className={styles.fileTime}>{updatedAt}</p>}
                </Col>
                <Col align="start" className={styles.actionmenu}>
                  <ActionMenu options={menuOptions as Array<ActionOption>} />
                </Col>
              </Row>
            </div>
          ) : (
            <>
              {showWithoutModal ? null : (
                <p className={styles.desc}>
                  Add your CV to get more tailored matches and show off your
                  background to recruiters!
                </p>
              )}
              {isCVUploading ? (
                <Spinner className={styles.loader} size="xsmall" />
              ) : null}

              <div
                {...getRootProps()}
                className={cn(
                  { [styles.dropbox]: !showWithoutModal },
                  {
                    [styles.dropboxBlur]: isCVUploading,
                  }
                )}
              >
                {showWithoutModal ? (
                  <>
                    <input {...getInputProps()} />
                    <Button
                      variant="outlined"
                      isFullWidth={true}
                      type="button"
                      onClick={open}
                    >
                      Add CV
                    </Button>
                  </>
                ) : (
                  <>
                    <input {...getInputProps()} />
                    <Icon iconName="doc-upload" size="xxlarge" />
                    {screens.sm ? (
                      <p onClick={open} className={styles.dropboxTitle}>
                        Tap here to upload your CV
                      </p>
                    ) : (
                      <>
                        <div className={styles.dropboxTitle}>
                          Drag your CV here or&nbsp;
                          <Button
                            variant="text"
                            className={styles.dropboxButton}
                            onClick={open}
                          >
                            browse
                          </Button>
                        </div>
                        <p className={styles.dropboxDesc}>
                          PDF (preferred), DOCX, DOC, RTF, TXT up to 2MB
                        </p>
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </section>
        {showWithoutModal ? null : !screens.sm ? (
          <>
            <div className={styles.separator}></div>
            <div className={styles.section}>
              <ButtonV2 width={128} onClick={onDone}>
                Done
              </ButtonV2>
            </div>
          </>
        ) : null}
      </div>
    );
  }
);

CVUpload.displayName = 'CVUpload';
