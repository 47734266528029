import React, { useEffect } from 'react';

export const useOnClickOutside = ({
  ref,
  onOutsideClick,
  onInsideClick,
}: {
  ref: React.MutableRefObject<HTMLElement | null>;
  onOutsideClick: (event: MouseEvent) => void;
  onInsideClick?: (event: MouseEvent) => void;
}): void => {
  useEffect(
    () => {
      const listener = (event: MouseEvent) => {
        if (!ref.current) return;

        if (ref.current.contains(<Node>event.target)) {
          if (onInsideClick) onInsideClick(event);
        } else {
          onOutsideClick(event);
        }
      };

      document.addEventListener('mousedown', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new
    // function on every render that will cause this effect
    // callback/cleanup to run every render. It's not a big deal
    // but to optimize you can wrap handler in useCallback before
    // passing it into this hook.
    [ref, onOutsideClick]
  );
};
