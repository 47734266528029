import React, { memo, useEffect, useMemo, useRef, useCallback } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';
import { Menu } from '@headlessui/react';
import { useSession } from 'next-auth/react';

import { Icon, IconButton, Link } from 'components';
import Logo from 'components/Logo';
import { ButtonV2 } from 'components/form';
import { signInModalInstance } from 'components/candidate/SignInModal';
import { CVUploadModalInstance } from 'components/candidate/CVUploadModal';
import { Row } from 'components/layout';
import { saveInAppModalInstance } from 'components/candidate/SaveInAppModal';
import { ActionMenuButton } from 'components/ActionMenuButton/ActionMenuButton.component';
import { ButtonV3 } from 'components/ComponentV2';
import HamburgerNavMenu from 'components/candidate/HamburgerNavMenu/HamburgerNavMenu.component';
import WalkthroughTooltip, {
  WalkthroughScrollType,
  WalkthroughTooltipType,
} from 'components/WalkthroughTooltip';
import { useResponsive } from 'hooks/useResponsive';
import { useNotification } from 'hooks/useNotification';
import { useCandidate } from 'hooks/useCandidate';
import ResponseModal from 'pages/candidate/applications/ResponseModal/ResponseModal.component';
import { PAGE_ROUTES } from 'lib/page-routes';
import { httpHeadersState } from 'lib/atoms/userSecretAtom';
import { parseResponse } from 'lib/utils/parser';
import { useApp } from 'lib/contexts/save-in-app-context';
import { useManagerFilters } from 'lib/contexts/manager-filters-context';
import { ApplicationResponseModalEnum } from 'lib/models/candidate-applications';
import { useAuth } from 'lib/providers/AuthProvider';
import { useCandidateDashboard } from 'lib/providers/CandidateDashboardProvider';
import { useCandidateMatches } from 'lib/providers/CandidateMatchesProvider';
import { isAndroid } from 'utils/dom';
import { APP_LINK } from 'utils/urls';
import styles from './Header.module.scss';

export interface HeaderProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  /** children node */
  children: React.ReactNode;
  showChatLogo?: boolean;
}

export const Header: React.FC<HeaderProps> = memo(
  ({ children, className }: HeaderProps) => {
    const notificationInstance = useNotification();
    const screens = useResponsive();
    const router = useRouter();
    const { data: session, status } = useSession();

    const { headers } = useRecoilValue(httpHeadersState);

    const {
      user,
      isCandidate,
      candidate,
      isTemporaryCandidate,
      isAuthLoading,
      isNonOnboardedUser,
    } = useAuth();

    const { extension_installed } = candidate || {};

    const heapIdentifiedRef = useRef(false);

    const { resetFilter: resetManagerFilter } = useManagerFilters();

    const {
      isPublicPage,
      isCandidateRoute,
      headerVisibleSmall,
      footerVisibleSmall,
      writeApplicationModalType,
      setWriteApplicationModalType,
      handleAutofillApplicationsClick,
    } = useApp();

    const {
      updateTouchpointApplicationRating,
      onGoogleLoginAuthentication,
      onAppleLoginAuthentication,
    } = useCandidate();
    const { matchesMeta } = useCandidateMatches();
    const unvisited_count = matchesMeta?.unvisited_count;
    const { candidateDashboard, totalUnreadPostCount } =
      useCandidateDashboard();

    const { upload_cv, rating, ta_id } = router.query;

    const {
      unread_messages_count = 0,
      unread_notifications_count = 0,
      started_or_matches_applications = [],
    } = useMemo(() => {
      const {
        unread_messages_count,
        unread_notifications_count,
        started_or_matches_applications,
      } = candidateDashboard || {};

      return {
        unread_messages_count,
        unread_notifications_count,
        started_or_matches_applications,
      };
    }, [candidateDashboard]);

    const isLoggedInViaGoogle = session && session.oauth_provider === 'google';
    const isLoggedInViaApple = session && session.oauth_provider === 'apple';

    useEffect(() => {
      if (status === 'authenticated') {
        (async () => {
          if (isLoggedInViaGoogle) {
            await onGoogleLoginAuthentication();
          } else if (isLoggedInViaApple) {
            await onAppleLoginAuthentication();
          }
        })();
      }
    }, [isLoggedInViaGoogle, isLoggedInViaApple, status]);

    useEffect(() => {
      if (!isNonOnboardedUser) {
        updateRatingHandler();
      }
    }, [isNonOnboardedUser]);

    const handleLoginClick = async () => {
      try {
        await signInModalInstance();
      } catch (error) {
        console.error(error);
      }
    };

    const handleCVUpload = async () => {
      try {
        await CVUploadModalInstance();
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      if (upload_cv === 'true') {
        handleCVUpload();
      }
    }, [upload_cv]);

    const updateRatingHandler = useCallback(async () => {
      if (rating && ta_id && !isNonOnboardedUser && isCandidate) {
        try {
          const response = await updateTouchpointApplicationRating(
            headers,
            ta_id as string,
            Number(rating)
          );
          if (response) {
            const applicationResponse = parseResponse(response);
            const { student_society_name } = applicationResponse;
            notificationInstance.custom({
              message: '',
              timeout: 10000,
              renderContent: (fn?: (() => void) | undefined) =>
                renderFeedbackToaster(student_society_name, fn),
            });
          }
        } catch (error) {
          notificationInstance.handleExceptionError(error);
        }
      }
    }, [rating, ta_id, isNonOnboardedUser, isCandidate]);

    const isCandidateDashboard = router.pathname.includes(
      PAGE_ROUTES.CANDIDATE_DASHBOARD
    );
    const isMatchesPage = router.pathname.includes(
      PAGE_ROUTES.CANDIDATE_MATCHES
    );
    const isCandidateSearchPage = router.pathname.includes(
      PAGE_ROUTES.SEARCH_RESULTS
    );
    const isCandidateAISearchPage = router.pathname.includes(
      PAGE_ROUTES.AI_SEARCH
    );

    const isTemporaryCandidateRoute =
      isPublicPage || router.pathname === PAGE_ROUTES.FUNNEL_MATCHES;

    useEffect(() => {
      if (isCandidate && !heapIdentifiedRef.current && user?.email) {
        heapIdentifiedRef.current = true;
        // @ts-ignore
        window.heap && window.heap.identify(user.email);
      }
    }, [isCandidate && user]);

    const onUseHuzzleClick = async () => {
      try {
        if (isTemporaryCandidate) {
          await saveInAppModalInstance();
        } else if (!isCandidate) {
          await signInModalInstance();
        }
        router.push(PAGE_ROUTES.CANDIDATE_DASHBOARD);
      } catch (error) {
        console.error(error);
      }
    };

    const onAppDownloadClick = async () => {
      try {
        if (isTemporaryCandidate) {
          await saveInAppModalInstance();
        } else if (!isCandidate) {
          await signInModalInstance();
        }
        router.push(APP_LINK);
      } catch (error) {
        console.error(error);
      }
    };

    /** Reset candidate filter if the  page route is not
     * specific search page or
     * public webpages
     */
    useEffect(() => {
      if (!isPublicPage && router.pathname !== PAGE_ROUTES.MANAGER) {
        resetManagerFilter();
      }
    }, [router]);

    const handleLogout = () =>
      router.push(`${PAGE_ROUTES.LOGOUT}?type=candidate`);

    const writeApplicationsModal = useMemo(() => {
      if (
        writeApplicationModalType &&
        writeApplicationModalType.type !==
          ApplicationResponseModalEnum.coverLetter
      ) {
        return (
          <ResponseModal
            modalType={writeApplicationModalType?.type}
            isOpen={true}
            application={writeApplicationModalType?.application || undefined}
            onClose={() => setWriteApplicationModalType(null)}
          />
        );
      }
    }, [writeApplicationModalType]);

    const totalMenuCount = useMemo(() => {
      const count = unread_notifications_count || 0;
      return screens.sm
        ? count + (unread_messages_count || 0) + (totalUnreadPostCount || 0)
        : count;
    }, [
      unread_notifications_count,
      unread_messages_count,
      totalUnreadPostCount,
      screens,
    ]);

    const desktopHamburgerMenu = (
      <Menu as="div" className={cn(styles.hamburger, styles.options)}>
        {({ close }) => (
          <>
            <Menu.Button className={styles.trigger}>
              <div className={styles['menu-icon']}>
                <Icon
                  className={styles.optionsIcon}
                  iconName="hamburger"
                  size="medium"
                />
                {totalMenuCount ? (
                  <p
                    className={cn(
                      styles.notificationBadge,
                      styles.notificationbubble
                    )}
                  >
                    {totalMenuCount}
                  </p>
                ) : null}
              </div>
              <p className={styles.optionsText}>Menu</p>
            </Menu.Button>
            <Menu.Items as="ul" className={styles.menuList}>
              <div className={styles['menu-group']}>
                <Menu.Item
                  as="li"
                  className={cn(styles.menuItem, styles.hidedesktop)}
                >
                  <button
                    className={styles.menubutton}
                    onClick={() => router.push(PAGE_ROUTES.CANDIDATE_MESSAGES)}
                  >
                    {unread_messages_count || totalUnreadPostCount > 0 ? (
                      <p className={cn(styles.notificationBadge, styles.new)}>
                        {(unread_messages_count || 0) +
                          (totalUnreadPostCount || 0)}
                      </p>
                    ) : null}
                    <p>Messages</p>
                  </button>
                </Menu.Item>
                <Menu.Item
                  as="li"
                  className={cn(styles.menuItem, styles.hidedesktop)}
                >
                  <button
                    className={styles.menubutton}
                    onClick={() => router.push(PAGE_ROUTES.CANDIDATE_PROFILE)}
                  >
                    <p>Profile</p>
                  </button>
                </Menu.Item>
                <Menu.Item as="li" className={styles.menuItem}>
                  <button
                    className={styles.menubutton}
                    onClick={() => router.push(PAGE_ROUTES.CANDIDATE_PROFILE)}
                  >
                    <p>Generate CV</p>
                  </button>
                </Menu.Item>
                <Menu.Item as="li" className={styles.menuItem}>
                  <button
                    className={styles.menubutton}
                    onClick={() => {
                      router.push(PAGE_ROUTES.CANDIDATE_BUILD_COVER_LETTER);
                      close();
                    }}
                  >
                    <p>Cover letter builder</p>
                  </button>
                </Menu.Item>
                <Menu.Item as="li" className={styles.menuItem}>
                  <button
                    className={styles.menubutton}
                    onClick={() => {
                      setWriteApplicationModalType({
                        type: ApplicationResponseModalEnum.questionResponse,
                        application: null,
                      });
                      close();
                    }}
                  >
                    <p>AI question replies</p>
                  </button>
                </Menu.Item>
                <Menu.Item as="li" className={styles.menuItem}>
                  <button
                    className={styles.menubutton}
                    onClick={handleAutofillApplicationsClick}
                  >
                    <p>Autofill applications</p>
                  </button>
                </Menu.Item>
              </div>
              <div className={styles.menuDivider}></div>
              <div className={styles['menu-group']}>
                <Menu.Item as="li" className={styles.menuItem}>
                  <button
                    className={styles.menubutton}
                    onClick={() =>
                      router.push(PAGE_ROUTES.CANDIDATE_NOTIFICATIONS)
                    }
                  >
                    <p>Notifications</p>
                    {unread_notifications_count ? (
                      <p className={cn(styles.notificationBadge, styles.new)}>
                        {unread_notifications_count}
                      </p>
                    ) : null}
                  </button>
                </Menu.Item>
                <Menu.Item as="li" className={styles.menuItem}>
                  <button
                    className={styles.menubutton}
                    onClick={() => {
                      router.push(
                        isNonOnboardedUser
                          ? PAGE_ROUTES.CANDIDATE_ONBOARDING
                          : `${PAGE_ROUTES.CANDIDATE_ONBOARDING}?matching_preference=true`
                      );
                    }}
                  >
                    <p>Matching preferences</p>
                  </button>
                </Menu.Item>
                <Menu.Item as="li" className={styles.menuItem}>
                  <button
                    className={styles.menubutton}
                    onClick={() => router.push(PAGE_ROUTES.CANDIDATE_SETTINGS)}
                  >
                    <p>Settings</p>
                  </button>
                </Menu.Item>
              </div>
              <div className={styles.menuDivider}></div>
              <div className={styles['menu-group']}>
                <Menu.Item as="li" className={styles.menuItem}>
                  <button
                    className={styles.menubutton}
                    onClick={() => handleLogout()}
                  >
                    <p>Sign out</p>
                  </button>
                </Menu.Item>
              </div>
            </Menu.Items>
          </>
        )}
      </Menu>
    );

    /** common for both mobile and desktop */
    const commonIconButtons = (
      <>
        <div
          onClick={() => router.push(PAGE_ROUTES.CANDIDATE_DASHBOARD)}
          className={cn(styles.options, {
            [styles.optionsActive]: router.pathname.includes(
              PAGE_ROUTES.CANDIDATE_DASHBOARD
            ),
          })}
        >
          <Icon
            className={styles.optionsIcon}
            iconName={isCandidateDashboard ? 'icon_home-fill' : 'icon_home'}
          />
          <p className={styles.optionsText}>Home</p>
        </div>
        <div
          onClick={() => router.push(PAGE_ROUTES.CANDIDATE_MATCHES)}
          className={cn(styles.options, {
            [styles.optionsActive]: router.pathname.includes(
              PAGE_ROUTES.CANDIDATE_MATCHES
            ),
          })}
        >
          {unvisited_count ? (
            <p className={cn(styles.notificationBadge, styles.matchesBubble)}>
              {unvisited_count}
            </p>
          ) : null}
          <Icon
            className={styles.optionsIcon}
            iconName={isMatchesPage ? 'heart-filled' : 'heart'}
          />
          <p className={styles.optionsText}>Matches</p>
        </div>
        <WalkthroughTooltip
          title={'Unlock Advanced AI Job Search'}
          description={
            'Access the most seamless job discovery, application support, and interview preparation – all in one intuitive experience.'
          }
          buttonOneText={'Next'}
          walkthroughType={WalkthroughTooltipType.DASHBOARD_EXPLORE}
          scrollToNextWalkthrough={
            WalkthroughScrollType.DASHBOARD_CHANGE_PREFERENCES
          }
          position={screens.sm ? 'top' : 'bottom'}
          progressCount={6}
          progressPosition={1}
          disable //hidden till feature is not released
        >
          <div
            onClick={() =>
              router.push(
                screens.sm ? PAGE_ROUTES.SEARCH_RESULTS : PAGE_ROUTES.AI_SEARCH
              )
            }
            className={cn(styles.options, {
              [styles.optionsActive]:
                isCandidateSearchPage || isCandidateAISearchPage,
            })}
          >
            <Icon
              className={styles.optionsIcon}
              iconName={
                isCandidateSearchPage ? 'icon_search-fill' : 'icon_search'
              }
            />
            <p className={styles.optionsText}>Explore</p>
          </div>
        </WalkthroughTooltip>
        <div
          onClick={() => router.push(PAGE_ROUTES.MANAGER)}
          className={cn(styles.options, {
            [styles.optionsActive]: router.pathname === PAGE_ROUTES.MANAGER,
          })}
        >
          <Icon
            className={styles.optionsIcon}
            iconName={
              router.pathname === PAGE_ROUTES.MANAGER
                ? 'icon_bookmark-filled'
                : 'icon_bookmark'
            }
          />
          <p className={styles.optionsText}>Manage</p>
        </div>
      </>
    );

    const signInActionMenuButton = [
      {
        id: '1',
        label: 'Sign in as student',
        onClick: async (event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          event.stopPropagation();
          await handleLoginClick();
        },
      },
      {
        id: '2',
        label: 'Sign in as recruiter',
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          event.stopPropagation();
          router.push(PAGE_ROUTES.SIGN_IN_RECRUITER);
        },
      },
    ];

    /** To hide icons from header on both Onboardings
     * Only show huzzle logo
     */
    const isMatchingPreference = () => {
      if (
        router.pathname === PAGE_ROUTES.CANDIDATE_ONBOARDING ||
        router.pathname === PAGE_ROUTES.AI_ONBOARDING
      ) {
        return router.query['matching_preference'] === 'true';
      }
      return true;
    };

    return (
      <>
        <>
          <header
            className={cn(
              styles.container,
              {
                [styles.containerTemporary]: isTemporaryCandidate && screens.sm,
                [styles['shown-mobile']]: headerVisibleSmall,
                [styles['display-header']]: true,
              },
              className
            )}
          >
            {!(
              screens.sm && router.pathname === PAGE_ROUTES.CANDIDATE_ONBOARDING
            ) ? (
              isTemporaryCandidate && isTemporaryCandidateRoute ? (
                <Row
                  align="center"
                  justify="space-between"
                  className={styles.tempcandidateheader}
                >
                  <Logo name="huzzle-new" size="small" color="primary" />
                  {screens.sm ? (
                    <IconButton
                      iconName={
                        isAndroid()
                          ? 'play-store-download'
                          : 'app-store-download'
                      }
                      size="auto"
                      className={styles.download}
                      onClick={onAppDownloadClick}
                    />
                  ) : (
                    <ButtonV2
                      onClick={onUseHuzzleClick}
                      className={styles.download}
                    >
                      Use huzzle
                    </ButtonV2>
                  )}
                </Row>
              ) : isCandidateRoute && isCandidate ? (
                <Link
                  href={PAGE_ROUTES.CANDIDATE_DASHBOARD}
                  className={styles.logoLink}
                >
                  <Logo name="huzzle-new" size="small" color="black" />
                </Link>
              ) : (
                <Link
                  href={PAGE_ROUTES.CANDIDATE_DASHBOARD}
                  className={styles.logoLink}
                >
                  <Logo
                    name={isCandidateRoute ? 'huzzle-new' : 'huzzle'}
                    size={
                      isCandidateRoute
                        ? 'small'
                        : screens.sm
                        ? 'small'
                        : 'medium'
                    }
                    color="black"
                  />
                </Link>
              )
            ) : null}
            {!isAuthLoading &&
              isCandidateRoute &&
              isMatchingPreference() &&
              (isCandidate ? (
                <Row align="center">
                  {commonIconButtons}
                  <div
                    onClick={() => router.push(PAGE_ROUTES.CANDIDATE_MESSAGES)}
                    className={cn(
                      styles.options,
                      {
                        [styles.optionsActive]:
                          router.pathname === PAGE_ROUTES.CANDIDATE_MESSAGES,
                      },
                      styles.position
                    )}
                  >
                    {(unread_messages_count || totalUnreadPostCount) > 0 ? (
                      <p
                        className={cn(
                          styles.notificationBadge,
                          styles.messagebubble
                        )}
                      >
                        {unread_messages_count + totalUnreadPostCount}
                      </p>
                    ) : null}
                    <Icon
                      className={styles.optionsIcon}
                      iconName={
                        router.pathname === PAGE_ROUTES.CANDIDATE_MESSAGES
                          ? 'icon_chat-filled'
                          : 'icon_chat'
                      }
                    />
                    <p className={styles.optionsText}>Messages</p>
                  </div>
                  <div
                    onClick={() => router.push(PAGE_ROUTES.CANDIDATE_PROFILE)}
                    className={cn(styles.options, {
                      [styles.optionsActive]:
                        router.pathname === PAGE_ROUTES.CANDIDATE_PROFILE,
                    })}
                  >
                    <Icon
                      className={styles.optionsIcon}
                      iconName={
                        router.pathname === PAGE_ROUTES.CANDIDATE_PROFILE
                          ? 'icon_profile-fill'
                          : 'icon_profile'
                      }
                    />
                    <p className={styles.optionsText}>Profile</p>
                  </div>
                  {desktopHamburgerMenu}
                </Row>
              ) : !isTemporaryCandidate ? (
                router.pathname === PAGE_ROUTES.SOCIETY_SPONSOR_PUBLIC_PAGE ? (
                  <ActionMenuButton
                    options={signInActionMenuButton}
                    buttonText="Sign in"
                    buttonColor="secondary"
                    menuOpenClassName={styles.activeActionButton}
                  />
                ) : (
                  <div className={styles.signInActions}>
                    <ButtonV3 onClick={handleLoginClick} color="secondary">
                      Sign in
                    </ButtonV3>
                    <HamburgerNavMenu />
                  </div>
                )
              ) : null)}
          </header>
          <div
            className={cn(
              styles.footer,
              {
                [styles.shown]: footerVisibleSmall,
              },
              { [styles.footerScrolled]: true }
            )}
          >
            <Row align="center" justify="space-around">
              {commonIconButtons}
              {desktopHamburgerMenu}
            </Row>
          </div>
        </>
        {children}
        {writeApplicationsModal}
      </>
    );
  }
);

Header.displayName = 'Header';

export function getLayout(page: React.ReactNode): React.ReactNode {
  // Add className as custom style to shared layout
  // @ts-ignore
  return (
    <Header
      // @ts-ignore
      className={this?.className}
      // @ts-ignore
      showChatLogo={this?.showChatLogo}
    >
      {page}
    </Header>
  );
}

export const renderAlreadyAppliedToaster = (
  onClose?: () => void
): React.ReactElement => {
  return (
    <div className={styles.toasterContent}>
      <span className={styles.toasterContentIcon}>✅</span>
      <div>
        <p className={styles.toasterContentTitle}>You’ve already applied!</p>
        <div className={styles.toasterContentFooter}>
          <p className={styles.toasterContentDescription}>
            Check your email for confirmation and application manager for
            updates.
          </p>
          <button className={styles.toasterContentDismiss} onClick={onClose}>
            Resend email
          </button>
        </div>
      </div>
    </div>
  );
};

export const renderEventAlreadyRegisteredToaster = (
  onClose?: () => void
): React.ReactElement => {
  return (
    <div className={styles.toasterContent}>
      <span className={styles.toasterContentIcon}>✅</span>
      <div>
        <p className={styles.toasterContentTitle}>You’ve already registered!</p>
        <div className={styles.toasterContentFooter}>
          <p className={styles.toasterContentDescription}>
            Check your email for registration ticket and instructions.
          </p>
          <button className={styles.toasterContentDismiss} onClick={onClose}>
            Resend email
          </button>
        </div>
      </div>
    </div>
  );
};

export const renderToasterWithTickmark = (
  message?: string
): React.ReactElement => {
  return (
    <div
      className={cn(styles.toasterContent, {
        [styles.toasterContentCenter]: true,
      })}
    >
      <span className={styles.toasterContentIcon}>✅</span>
      <p className={styles.toasterContentTitle}>{message}</p>
    </div>
  );
};

export const renderEventAlreadyWaitlistedToaster = (
  onClose?: () => void
): React.ReactElement => {
  return (
    <div className={styles.toasterContent}>
      <span className={styles.toasterContentIcon}>✅</span>
      <div>
        <p className={styles.toasterContentTitle}>
          You’re already on waitlist!
        </p>
        <div className={styles.toasterContentFooter}>
          <p className={styles.toasterContentDescription}>
            Check your email for registration ticket and instructions.
          </p>
          <button className={styles.toasterContentDismiss} onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export const renderFeedbackToaster = (
  societyName: string,
  onClose?: () => void | undefined
): React.ReactElement => {
  return (
    <div className={styles.toasterContent}>
      <span className={styles.toasterContentIcon}>🙌</span>
      <div>
        <p className={styles.toasterContentTitle}>Thank you!</p>
        <div className={styles.toasterContentFooter}>
          <p className={styles.toasterContentDescription}>
            {societyName} and Huzzle appreciate your feedback and will aim to
            improve future events.
          </p>
          <button className={styles.toasterContentDismiss} onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export const renderPreferencesToaster = (
  onClose?: () => void
): React.ReactElement => {
  return (
    <div className={styles.toasterContent}>
      <span className={styles.toasterContentIcon}>🥁</span>
      <div>
        <p className={styles.toasterContentTitle}>
          You’ve found your preferences!
        </p>
        <div className={styles.toasterContentFooter}>
          <p className={styles.toasterContentDescription}>
            Personalise them now or change them anytime from the home feed.
          </p>
          <button className={styles.toasterContentDismiss} onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export const renderExpiredToaster = (
  onClose?: () => void,
  type?: string
): React.ReactElement => {
  return (
    <div className={styles.toasterContent}>
      <span className={styles.toasterContentIcon}>🔎</span>
      <div>
        <p className={styles.toasterContentTitle}>
          {`The ${type} has expired`}
        </p>
        <div className={styles.toasterContentFooter}>
          <p className={styles.toasterContentDescription}>
            {`Search for other live ${type}s below!`}
          </p>
          <button className={styles.toasterContentDismiss} onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export const renderFeatureNotification = (
  link: string,
  title: string,
  onClose?: () => void | undefined
): React.ReactElement => {
  return (
    <div className={styles.toasterContent}>
      <span className={styles.toasterContentIcon}>
        <Icon iconName="icon_star" />
      </span>
      <div>
        <p className={styles.toasterContentTitle}>{title}</p>
        <div className={styles.toasterContentFooter}>
          <p className={styles.toasterContentDescription}>
            Check your{' '}
            <u>
              <Link href={link} target="_blank">
                company profile page
              </Link>
            </u>{' '}
            to see how it looks for candidates.
          </p>
          <button className={styles.toasterContentDismiss} onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export const renderInvalidURLReportToaster = (
  onClose?: () => void
): React.ReactElement => {
  return (
    <div className={styles.toasterContent}>
      <span className={styles.toasterContentIcon}>🙌</span>
      <div>
        <p className={styles.toasterContentTitle}>Thank you!</p>
        <div className={styles.toasterContentFooter}>
          <p className={styles.toasterContentDescription}>
            We will investigate it ASAP and let you know once we have it fixed.
          </p>
          <button className={styles.toasterContentDismiss} onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};
