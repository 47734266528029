import React, { useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import cn from 'classnames';
import Icon, { IconName } from 'components/Icon';
import { ButtonV3 } from 'components/ComponentV2';

import styles from './ActionMenuButton.module.scss';

export type ActionOption = {
  id: string;
  label: string | null | React.ReactNode;
  icon?: IconName;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onHover?: (event: React.MouseEvent<HTMLLIElement>) => void;
  danger?: boolean;
  isIconPrimaryColor?: boolean;
  secondaryColor?: boolean;
  options?: Array<ActionOption>;
  actionElement?: React.ReactNode;
};

type ActionMenuProps = {
  options: Array<ActionOption>;
  align?: 'left' | 'right' | 'auto';
  className?: string;
  listClassName?: string;
  subOptionsClassName?: string;
  subOptionsAlignClassName?: { right: string; left: string; auto?: string };
  optionClassName?: string;
  disabled?: boolean;
  isModal?: boolean;
  modalHeight?: number;
  buttonColor?: 'primary' | 'secondary';
  buttonText?: string;
  buttonElement?: React.ReactNode;
  buttonStartIcon?: IconName;
  buttonEndIcon?: IconName;
  buttonSize?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
  menuButtonClass?: string;
  menuButtonOpenClassName?: string;
  buttonClassName?: string;
  dropdownDirection?: 'auto' | 'top' | 'bottom';
  menuOpenClassName?: string;
  isButtonBorderless?: boolean;
  actionMenuStyle?: Record<string, unknown>;
};

export const ActionMenuButton: React.FC<ActionMenuProps> = ({
  options,
  align = 'left',
  className = '', // custom class name
  listClassName,
  subOptionsClassName,
  subOptionsAlignClassName,
  optionClassName,
  disabled,
  isModal,
  modalHeight = 0,
  buttonColor = 'primary',
  buttonText,
  buttonElement,
  buttonStartIcon,
  buttonEndIcon,
  buttonSize = 'medium',
  menuButtonClass,
  menuButtonOpenClassName = '',
  buttonClassName,
  dropdownDirection = 'auto',
  menuOpenClassName = '',
  isButtonBorderless = false,
  actionMenuStyle,
}) => {
  const [openDirectionBottom, setOpenDirectionBottom] = useState<boolean>(true);
  let pageSize = 0;

  useEffect(() => {
    pageSize =
      isModal && modalHeight ? modalHeight : document.body.scrollHeight;
  });

  useEffect(() => {
    if (dropdownDirection !== 'auto') {
      setOpenDirectionBottom(dropdownDirection === 'bottom');
    }
  }, [dropdownDirection]);

  const handleButtonClick: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    if (dropdownDirection === 'auto') {
      if (pageSize - event.pageY < options.length * 110) {
        setOpenDirectionBottom(false);
      } else {
        setOpenDirectionBottom(true);
      }
    }
  };

  return (
    <Menu as="div" className={cn(styles.ActionMenuButton, className)}>
      {({ open }) => (
        <>
          <Menu.Button
            className={cn(styles.buttonWrapper, menuButtonClass, {
              [menuButtonOpenClassName]: open,
            })}
            as="div"
            disabled={disabled}
          >
            {buttonText ? (
              <ButtonV3
                className={cn(styles.buttonWrapperButton, buttonClassName, {
                  [menuOpenClassName]: open,
                })}
                onClick={!disabled ? handleButtonClick : () => null}
                disabled={disabled}
                endIcon={buttonEndIcon}
                size={buttonSize}
                endIconClassName={styles.downArrow}
                color={buttonColor}
                startIcon={buttonStartIcon}
                borderless={isButtonBorderless}
              >
                {buttonText}
              </ButtonV3>
            ) : (
              buttonElement
            )}
          </Menu.Button>
          <Transition
            enter={styles.enter}
            enterFrom={styles['enter-from']}
            enterTo={styles['enter-to']}
            leave={styles.leave}
            leaveFrom={styles['leave-from']}
            leaveTo={styles['leave-to']}
            as="div"
          >
            {options.length > 0 && (
              <Menu.Items
                as="ul"
                static
                className={cn(
                  styles.options,
                  openDirectionBottom
                    ? styles.optionsTop
                    : styles.optionsBottom,
                  alignClassName[align],
                  listClassName
                )}
                style={actionMenuStyle}
              >
                {options.map((option) => (
                  <div
                    key={option.id}
                    className={cn({ [styles.option]: option.label !== null })}
                  >
                    {option.options ? (
                      <Menu as="div" className={styles.subContainer}>
                        <div onClick={(e) => e?.stopPropagation()}>
                          <Menu.Button
                            as="li"
                            key={option.id}
                            className={styles.optionsItem}
                            onMouseEnter={option.onHover}
                          >
                            {option.label}
                            {option.icon && <Icon iconName={option.icon} />}
                          </Menu.Button>
                        </div>
                        <Menu.Items
                          as="ul"
                          className={cn(
                            styles.subOptions,
                            subOptionsAlignClassName
                              ? subOptionsAlignClassName[align]
                              : alignClassName[align],
                            subOptionsClassName
                          )}
                        >
                          {option.options.map((option) => (
                            <Menu.Item as="li" key={option.id}>
                              <button
                                onClick={option.onClick}
                                className={cn(styles.item, {
                                  [styles.danger]: option.danger,
                                  [styles.primaryColor]:
                                    option.isIconPrimaryColor,
                                  [styles.secondaryColor]:
                                    option.secondaryColor,
                                })}
                              >
                                <p className={styles.label}>{option.label}</p>
                                <div>{option.actionElement}</div>
                                {option.icon && <Icon iconName={option.icon} />}
                              </button>
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Menu>
                    ) : (
                      option.label && (
                        <Menu.Item as="li">
                          <button
                            className={cn(styles.item, optionClassName, {
                              [styles.danger]: option.danger,
                              [styles.primaryColor]: option.isIconPrimaryColor,
                              [styles.secondaryColor]: option.secondaryColor,
                            })}
                            onClick={option.onClick}
                          >
                            <p className={styles.label}>{option.label}</p>
                            <div className={styles.actionElement}>
                              {option.actionElement}
                            </div>
                            {option.icon && <Icon iconName={option.icon} />}
                          </button>
                        </Menu.Item>
                      )
                    )}
                  </div>
                ))}
              </Menu.Items>
            )}
          </Transition>
        </>
      )}
    </Menu>
  );
};

const alignClassName = {
  left: styles.left,
  right: styles.right,
  auto: styles.auto,
};
