import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { Menu } from '@headlessui/react';
import React, { ReactNode, useMemo } from 'react';
import { useRouter } from 'next/router';

import Icon, { IconSize } from 'components/Icon';
import { useCandidate } from 'hooks/useCandidate';
import { PAGE_ROUTES } from 'lib/page-routes';
import { useAuth } from 'lib/providers/AuthProvider';

import styles from './HamburgerNavMenu.module.scss';

interface MenuItemType {
  id: number;
  label?: string;
  render?: ReactNode;
  showDivider?: boolean;
  handler?: () => void;
}

interface HamburgerNavMenuProps {
  className?: string;
  triggerClassName?: string;
  menuListClassName?: string;
  iconSize?: IconSize;
}

const HamburgerNavMenu: React.FC<HamburgerNavMenuProps> = (props) => {
  const {
    className,
    triggerClassName,
    menuListClassName,
    iconSize = 'medium',
  } = props;
  const [tCommon] = useTranslation('common');
  const router = useRouter();
  const { isCandidate } = useAuth();
  const { redirectToCoverLetterBuilder } = useCandidate();

  const signedInUserMenuItems: MenuItemType[] = useMemo(
    () => [
      {
        id: 1,
        label: tCommon('home'),
        handler: () => router.push(PAGE_ROUTES.CANDIDATE_DASHBOARD),
      },
      {
        id: 2,
        label: tCommon('matches'),
        handler: () => router.push(PAGE_ROUTES.CANDIDATE_MATCHES),
      },
      {
        id: 3,
        label: tCommon('explore'),
        handler: () => router.push(PAGE_ROUTES.SEARCH_RESULTS),
      },
      {
        id: 4,
        label: tCommon('manage'),
        handler: () => router.push(PAGE_ROUTES.MANAGER),
      },
      {
        id: 5,
        label: tCommon('messages'),
        handler: () => router.push(PAGE_ROUTES.CANDIDATE_MESSAGES),
      },
      {
        id: 6,
        label: tCommon('profile'),
        handler: () => router.push(PAGE_ROUTES.CANDIDATE_PROFILE),
      },
    ],
    [tCommon]
  );

  const signedOutUserMenuItems: MenuItemType[] = useMemo(
    () => [
      {
        id: 1,
        label: tCommon('home'),
        handler: () => router.push(PAGE_ROUTES.CANDIDATE_DASHBOARD),
      },
      {
        id: 2,
        label: tCommon('jobs'),
        handler: () =>
          router.push({
            pathname: PAGE_ROUTES.SEARCH_RESULTS,
            query: { type: 'jobs' },
          }),
      },
      {
        id: 3,
        label: tCommon('internships'),
        handler: () =>
          router.push({
            pathname: PAGE_ROUTES.SEARCH_RESULTS,
            query: { type: 'internships' },
          }),
      },
      {
        id: 4,
        label: tCommon('events'),
        handler: () =>
          router.push({
            pathname: PAGE_ROUTES.SEARCH_RESULTS,
            query: { type: 'events' },
          }),
      },
      {
        id: 5,
        label: '',
        showDivider: true,
      },
      {
        id: 6,
        label: tCommon('hamburger-nav-menu.create-cv-with-ai'),
        handler: () => router.push(PAGE_ROUTES.CANDIDATE_PROFILE),
      },
      {
        id: 7,
        label: tCommon('hamburger-nav-menu.create-cover-letter'),
        handler: () => redirectToCoverLetterBuilder(),
      },
      {
        id: 9,
        label: '',
        showDivider: true,
      },
      {
        id: 10,
        render: (
          <div className={styles.getStarted}>
            <span>{tCommon('get-started')}</span>
            <Icon iconName="icon_arrow-right-2" size="xsmall" />
          </div>
        ),
        handler: () => router.push(PAGE_ROUTES.CANDIDATE_ONBOARDING),
      },
    ],
    []
  );

  const itemsToRender: MenuItemType[] = useMemo(() => {
    return isCandidate ? signedInUserMenuItems : signedOutUserMenuItems;
  }, [isCandidate]);

  return (
    <Menu
      as="div"
      className={cn(styles.HamburgerNavMenu, className)}
      data-testid="HamburgerNavMenu"
    >
      <Menu.Button
        className={cn(styles.HamburgerNavMenuTrigger, triggerClassName)}
      >
        <Icon iconName="icon_menu_bold" size={iconSize} />
      </Menu.Button>
      <Menu.Items
        as="ul"
        className={cn(styles.HamburgerNavMenuList, menuListClassName)}
      >
        {itemsToRender.map((option) =>
          option.showDivider ? (
            <div key={option.id} className={styles.divider}></div>
          ) : (
            <Menu.Item key={option.id} as="li">
              <button
                className={styles.HamburgerNavMenuButton}
                onClick={option.handler}
              >
                {option.label ? <p>{option.label}</p> : option.render}
              </button>
            </Menu.Item>
          )
        )}
      </Menu.Items>
    </Menu>
  );
};

HamburgerNavMenu.displayName = 'HamburgerNavMenu';

export default HamburgerNavMenu;
