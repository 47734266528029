import React, { useCallback } from 'react';
import { create, InstanceProps } from 'react-modal-promise';

import { IconButton } from 'components';
import Modal from 'components/Modal';
import { useApp } from 'lib/contexts/save-in-app-context';
import SaveInApp from '../SaveInApp';

import styles from './SaveInAppModal.module.scss';

const ModalInstance: React.FC<InstanceProps<Headers>> = ({
  isOpen,
  onResolve,
  onReject,
}) => {
  const { setCallback } = useApp();

  const onComplete = useCallback(() => {
    onResolve();
    setCallback(null);
  }, [setCallback, onResolve]);

  const onCancel = useCallback(() => {
    onReject();
    setCallback(null);
  }, [setCallback, onReject]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      contentClassName={styles.container}
    >
      <IconButton
        size="xsmall"
        iconName="close"
        className={styles.cancelIcon}
        onClick={onCancel}
      />
      <SaveInApp
        onComplete={onComplete}
        onCancel={onCancel}
        className={styles.content}
      />
    </Modal>
  );
};

export const saveInAppModalInstance = create(ModalInstance);
